import { Button, Container, List, ListItem, styled } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Conversation } from '../../Types/conversation';
import ChatCard from './ChatCard';

const NewChatButton = styled(Button)({
  width: 'inherit',
  margin: '8px 16px',
  bgcolor: '#0076cc',
}) as typeof Button;

const NewChatButtonContainer = styled('div')({
  display: 'flex',
  width: '100%',
});

const CenteredContentList = styled(List)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

export type ChatHistoryProps = {
  conversations: Conversation[];
  handleConversationUpdate: (conversationId?: string) => void;
};

const ChatHistory = ({
  conversations,
  handleConversationUpdate,
}: ChatHistoryProps): JSX.Element => {
  const navigate = useNavigate();

  const handleNewChat = async () => {
    navigate('/chat');
    // So default chat info shows up after clicking new chat
    handleConversationUpdate(undefined);
  };

  return (
    <Container
      style={{ overflowY: 'auto', padding: '0px', backgroundColor: '#fff', height: 'inherit' }}
    >
      <CenteredContentList>
        <NewChatButtonContainer>
          <NewChatButton
            variant="contained"
            sx={{ bgcolor: '#F36E24', '&:hover': { bgcolor: '#C14610' } }}
            onClick={() => handleNewChat()}
          >
            New Chat
          </NewChatButton>
        </NewChatButtonContainer>
        {conversations.map((conversation) => (
          <ListItem key={conversation.id}>
            <ChatCard
              conversation={conversation}
              onConversationCardClick={handleConversationUpdate}
            />
          </ListItem>
        ))}
      </CenteredContentList>
    </Container>
  );
};

export default ChatHistory;
